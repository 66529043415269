import request from '@/utils/request'

export function getHobbiesById(data) {
  return request({
    url: `/api/Hobbies/GetHobbiesById/${data}`,
    method: 'get',
  })
}

export function getHobbies() {
  return request({
    url: '/api/Hobbies/GetHobbies',
    method: 'get',
  })
}

export function addHobby(data) {
  return request({
    url: '/api/Hobbies/AddHobbies',
    method: 'post',
    data,
  })
}

export function updateHobby(data) {
  return request({
    url: '/api/Hobbies/UpdateHobbies',
    method: 'put',
    data,
  })
}
export function deleteHobby(data) {
  return request({
    url: `/api/Hobbies/DeleteHobbies?HobbiesId=${data}`,
    method: 'delete',
  })
}
